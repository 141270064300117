<template>
    <section  style="background-image: url('assets/images/banner.jpg'); background-size: cover;">
        <div class="container">
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6" style="text-align: center; padding-top: 70px; background-color: #fff;">

                    <Banner />

                    <p>Pick a date</p>

                    <div>
                        <input type="date" v-model="picked_date" class="form-control input-design" @change="get_date()">  
                    </div>

                    <p>Pick a time</p>
                    <div>
                        <input type="time" v-model="picked_time" class="form-control input-design" min="07:00:00">
                    </div>

                    <div style="padding-top: 20px;">
                        <router-link to="/" class="btn theme-btn-1" style="margin-right: 5px;">Back</router-link>
                        <button @click="choose_date()" class="btn theme-btn-1">Next</button>
                    </div>

                </div>
                <div class="col-md-3"></div>
            </div>
        </div>
    </section>
</template>

<script>
import Banner from './layouts/Banner'
export default{
    name : 'Home',
    components : {Banner},
    data (){
        return {
            picked_date : '',
            picked_time : ''
        }
    },
    methods: {
      choose_date(){
        if(!this.picked_date){
            alert('Please pick a date')
            return 
        }

        if(!this.picked_time){
            alert('Please pick a time')
            return 
        }

        if(this.get_date()==='bad'){
            alert('Orders  accepted on Saturdays only')
            return
        }

        localStorage.setItem('picked_date',this.picked_date)
        localStorage.setItem('picked_time',this.picked_time)
        this.$router.push('/food-menu')
      },

      get_date(){
    var day = new Date(this.picked_date).getUTCDay();
    if([6].includes(day)){
    return 'good'
  }else{
    return 'bad'
  }
      }
    }
}
</script>