<template>
    <section>
        <div class="container">
            <div class="row">
              
                <div class="col-md-12" style="text-align: center; padding-top: 70px;">

                    <Banner />

                    <p>Book table</p>
                 
                    <div class="row">

                        <div class="col-md-4" v-for="food in foods">

                            <div class="shadow p-3 mb-5 bg-body rounded">

                            <div :style=" { backgroundImage : `url(${this.$store.state.api_url}assets/images/menu/${food.image})`, backgroundSize : 'cover', height: '200px', backgroundPosition : 'center' }"></div>
                            <div style="padding-top: 5px;">
                                <h4>{{ food.description }}</h4>
                                <h4 style="font-weight: 700;">Ksh.{{ food.cost }}</h4>
                                <button class="btn theme-btn-1" @click="add_food(food.id,food.cost)">Add to cart</button>
                            </div>
                            </div>

                        </div>

                        

                        


                    </div>

                    <div class="box">
                        <button v-if="show_button" @click="checkout()" class="btn theme-btn-1">({{cart.length}})Check Out</button>
                    </div>


                    <div class="box-2">
                        <button class="btn theme-btn-1">Total Ksh.{{total_cost}}</button>
                    </div>

                </div>
                
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Banner from './layouts/Banner'
export default{
    name : 'Home',
    components : {Banner},
    data (){
        return {
            foods : [],
            cart : [],
            total_cost : 0,
            show_button : true
        }
    },
    methods: {
    
        async get_food(){
            const res = await axios.get(this.$store.state.api_url+'api/get-food').then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.foods = res
        },

        add_food(food_id,cost){
            this.cart.push(food_id)
            this.total_cost = parseInt(cost)+parseInt(this.total_cost)
            localStorage.setItem('food_cost',this.total_cost)

            localStorage.setItem('total_cost', parseInt(localStorage.getItem('food_cost')) + parseInt(localStorage.getItem('seat_cost'))  )
            this.$store.state.cart = this.cart
           
        },

        checkout(){
            if( parseInt(this.cart.length) < parseInt(localStorage.getItem('seats')) ){
                alert('Number of orders is less than number of seats.To proceed with this order,number of orders must be equal to or more than number of seats')
            }else{
                this.show_button = false
           this.$router.push('/checkout')
            }
        }
        
    },
    created(){
        this.get_food()
    }
}
</script>


<style scoped>
.box{
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 20px;
    margin-bottom: 20px;  
}

.box-2{
    position: fixed;
    top: 0;
    right: 0;
    margin-right: 20px;
    margin-top: 20px;  
}
</style>