<template>
<div class="container">
<nav class="header-style navbar navbar-expand-sm navbar-ligh bg-ligh">
  <div class="container-fluid">

    <a class="navbar-brand logo" href="javascript:void(0)">
        <img src="assets/images/logo-2.png" style="height: 70px;">

    </a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="mynavbar">
      <ul class="navbar-nav mx-auto">

        <li class="nav-item">
          <router-link to="/dashboard" class="nav-link">Dashboard</router-link>
        </li> 

        <li class="nav-item">
          <router-link to="/orders" class="nav-link">Orders</router-link>
        </li> 
        
        <li class="nav-item">
          <router-link to="/seats" class="nav-link">Seats</router-link>
        </li> 

        <li class="nav-item">
          <router-link to="/menu" class="nav-link">  Menu</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/profile" class="nav-link btn btn-outline-danger">  Profile</router-link>
        </li>

      </ul>

      <form class="d-flex">

      
        <router-link to="/login" class="btn btn-danger">Log Out</router-link>
       
      </form>
    </div>
  </div>
</nav>
</div>
</template>