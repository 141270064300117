<template>
    <section style="background-image: url('assets/images/s1.jpg'); background-size: cover;">
        <div class="container">
          
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6" style="text-align: center; padding-top: 20px; background-color: #fff;">

                    <Banner />
                    <div class="alert alert-danger">
                        Book your seats online avoid extra charges.
                        Booking closes Saturdays 4:00PM<br/>
                        Once an order has been made it cannot be cancelled and its not refundable
                    </div>
                 
                    <p>Choose number of guests</p>
                    
                    <div>
                        <button v-for="st in seats_array" class="btn seats-btn" @click="set_seats(st)">{{ st }}</button>
                    </div>

                    <h3>Or</h3>
                    <div>
                        <input type="text" v-model="seats" class="form-control input-design" placeholder="Enter manually">
                    </div>

                    <div style="padding-top: 20px; padding-bottom: 20px;">
                        <button @click="get_seats()" class="btn theme-btn-1">Next</button>
                    </div>

                </div>
                <div class="col-md-3" style="text-align: right; padding: 5px;">
                  <router-link to="/login" class="btn theme-btn-1">Login</router-link>
                </div>
            </div>
        </div>
    </section>

    <section style="padding-top: 50px;;">
        <div class="container shadow-lg rounded" style="background-color: #DDFFDD; border-radius: 10px;">

            <div class="row">

                <div class="col-md-4">
                    <div style="text-align: center; padding-top: 30px; color: #13331C; padding-bottom: 30px;">
                        <h2> <i class="bi bi-envelope"></i> </h2>
                        <h5>info@keshkeshcafe.com</h5>
                    </div>
                </div>

                <div class="col-md-4">
                    <div style="text-align: center; padding-top: 30px; color: #13331C; padding-bottom: 30px;">
                        <h2> <i class="bi bi-phone"></i> </h2>
                        <h5>+254113060037</h5>
                    </div>
                </div>

                <div class="col-md-4">
                    <div style="text-align: center; padding-top: 30px; color: #13331C; padding-bottom: 30px;">
                        <h2> <i class="bi bi-geo-alt"></i> </h2>
                        <h5>info@keshkeshcafe.com</h5>
                    </div>
                </div>

            </div>

        </div>
    </section>


    <section style="padding-top: 50px;">
        <div class="container shadow-lg rounded">
            <div class="row">   
                <div class="col-md-6" style="padding-top: 70px; text-align: center; padding-bottom: 10px;;">
                    <h3>Get Offers!</h3>
                    <h1>On all orders made online</h1>
                    <a href="" class="btn theme-btn-1">Contact us</a>
                </div>

                <div class="col-md-6">
                    <img src="assets/images/banner-2.jpg" style="width:100%;">
                </div>

            </div>
        </div>
    </section>

</template>

<script>
import axios from 'axios'
import Banner from './layouts/Banner'
import Header from './layouts/Header'
export default{
    name : 'Home',
    components : {Banner,Header},
    data (){
        return {
            seats : '',
            seats_array : [1,2,3,4,5,6,7,8,9,10,11,12],
            available_seats : 0
        }
    },
    methods: {
        set_seats(seats){
            this.seats = seats
        },

       

        get_seats(){
            if(!this.seats){
                alert('Enter number of guests')
                return
            }

            if(parseInt(this.seats)<1){
                alert('Enter correct number of guests')
                return
            }

            this.get_total_seats()
        },

        async get_total_seats(){
        const res = await axios.get(this.$store.state.api_url+'api/get-seats').then(function(response){
            return response.data
        }).catch(function(error){

        })
        
        this.available_seats = res

        if(parseInt(this.available_seats)<1){
            alert('We have 0 seats remaining.We are sorry you cannot book at the moment')
        }else if( parseInt(this.seats) > parseInt(this.available_seats) ){
            alert('We have ' + this.available_seats +  ' seats remaining.Pick a lesser number')
            return
        }else{

        localStorage.setItem('seats',this.seats)
        localStorage.setItem('seat_cost',parseInt(this.seats)*0)
        this.$router.push('/food-menu')

        }
        
     },


    },
    created(){
     
    }
}
</script>